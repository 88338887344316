<template>
<ModalForm
    id="modalPartialPayment"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <RowForm :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Responsable</label>
            <SelectOption
                class="itemControl2"
                name="responsibleId"
                :options="comboResponsibles"
                :disabled="readOnly"
            />
        </template>
    </RowForm>
    <br>
    <RowForm :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">Tipo de Moneda</label>
            <SelectOption
                class="itemControl"
                name="currencyId"
                :options="comboCurrency"
                :disabled="readOnly"
            />
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">Importe</label>
            <Field  name="amount" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        placeholder=""
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
    </RowForm>
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../../components/c-modal-form/ModalFormMaster.vue";
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { useRouter } from "vue-router";
// import SelectOption from '@/components/forms/Select.vue'

export default defineComponent({
    components:{
        Field,
        ModalForm,
        SelectOption,
        RowForm
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Nuevo Abono"
        },
        purchaseOrderId: {
            type: String,
            required: true,
            default: () => ""
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
        redirectToDetails: {
            type: Boolean,
            required: false,
            default: () => false
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
    },
    setup(props) {
        const { mode, purchaseOrderId, redirectToDetails, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let responsibles = ref([]);
        let currencys = ref([]);
        let txtName = ref("");
        let controller = "SettlementPurchaseOrders";
        const router = useRouter();
        const validationSchema = Validations.object().shape({
            responsibleId: Validations.string().required().label("Responsable")
            , amount: Validations.number().required().label("Importe")
            , currencyId: Validations.string().required().label("Tipo de Moneda")
        });

        onBeforeMount(async() => {
            await getComboOptions("Responsibles", responsibles);
            await getComboOptions("Currency", currencys);
        });

        watch(currentOpenedItem, async(nValue) => {
            debugger
            modalForm.value.$refs.form.setFieldValue("responsibleId", currentOpenedItem.value.responsibleId);
            modalForm.value.$refs.form.setFieldValue("amount", currentOpenedItem.value.amount);
            modalForm.value.$refs.form.setFieldValue("currencyId", currentOpenedItem.value.currencyId);
        });

        const initialValues = computed(() => {
            let po = {};
            // debugger
            // if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
            //     po.folio = currentOpenedItem.value.folio;
            //     po.supplierId = currentOpenedItem.value.supplierId;
            //     po.dateOfDelivery = new Date(currentOpenedItem.value.dateOfDelivery);
            //     po.purchaseOrderSupplierFolio = currentOpenedItem.value.purchaseOrderSupplierFolio;
            //     po.creditDays = currentOpenedItem.value.creditDays;
            //     po.freightNumber = currentOpenedItem.value.freightNumber;
            //     po.observations = currentOpenedItem.value.observations;
            // }
            
            return po;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboResponsibles = computed(() => { return responsibles.value });
        const comboCurrency = computed(() => { return currencys.value });

        //FUNCIONES
         const initDefaults = () => {
            modalForm.value.$refs.form.resetForm();
            setComboValues(currencys, "currencyId");
            modalForm.value.$refs.form.setFieldValue("amount", 0);
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            }
        }

        const saveNewItem = async(item) => {
            ApiService.post(`${controller}/${purchaseOrderId.value}`, item).then(res => {
                if(res.data.id) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");

                    if(redirectToDetails) {
                        let dataProps = `purchaseOrderId:${purchaseOrderId.value}`;
                        router.push({
                            name: '/shopping/settlementPurchaseOrders/:C0',
                            params: {
                                C0: btoa(dataProps)
                            }
                        });
                        if(props.fnCallBackPersistenceNotifier) {
                            item.responsibleName = responsibles.value.find(r => r.id == item.responsibleId).name;
                            item.currencyName = currencys.value.find(c => c.id == item.currencyId).name;
                            item.paymentDate = new Date().toLocaleDateString();
                            props.fnCallBackPersistenceNotifier(item);
                        }
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService, "message");
            });

            // let dataProps = `purchaseOrderId:${purchaseOrderId.value}`;

            // router.push({
            //     name: '/shopping/settlementPurchaseOrders/:C0',
            //     params: {
            //         C0: btoa(dataProps)
            //     }
            // });
        }

        // const saveUpdatedItem = async(item) => {
        //     let url = `${controller}/${currentOpenedItem.value.id}`;
        //     item.id = currentOpenedItem.value.id;
        //     currentOpenedItem.value.supplierId = item.supplierId;
        //     item.supplierName = suppliers.value.some(s => s.id == item.supplierId) ? suppliers.value.find(s => s.id == item.supplierId).name : "";
        //     item.tax = currentOpenedItem.value.tax;
        //     item.total = currentOpenedItem.value.total;
        //     await ApiService.put(url, currentOpenedItem.value).then(res => {
        //         if(res.status == 204) {
        //             modalForm.value.closeModal();
        //             messageService.success("La información se guardó exitosamente.");
        //             if(props.fnCallBackPersistenceNotifier) {
        //                 props.fnCallBackPersistenceNotifier(item);
        //             }
        //         }            
        //     }).catch(reject => {
        //         getMessageError(reject, messageService);
        //     });
        // }

        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const setComboValues = (options, fieldId) => {
            if(options.value.length == 1) {
                modalForm.value.$refs.form.setFieldValue(fieldId, currencys.value[0].id);
            }
        }

        return {
            editForm,
            modalForm,
            txtName,
            validationSchema,
            initialValues,
            comboResponsibles,
            comboCurrency,
            readOnly,
            isEditing,

            //Funciones
            handleCrudAction,
            initDefaults
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 250px;
    }

    .itemControl2 {
        width: 510px;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>

<i18n>
{
    "es":{
        "FIELDS": {
            "FOLIO": "Folio",
            "DELIVERY_DATE": "Fecha de Entrega",
            "SUPPLIER": "Proveedor",
            "SUPPLIER_FOLIO": "Folio de Compra del Proveedor",
            "CREDIT_DAYS": "Días de Crédito",
            "FREIGHT_NUMBER": "Número de Carga",
            "OBSERVATIONS": "Observaciones"
        }
    }
}
</i18n>
